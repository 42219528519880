import React from 'react';
import './App.css';

import AppTop from './AppTop.js';
import AppBottom from './AppBottom.js';
import AppTable from './AppTable.js';
import AppArticle from './AppArticle.js';
import AppAbout from './AppAbount.js';

import AppTool from './tool/AppTool.js';


class App extends React.Component {

  render() {

    var content;
    if(document.getElementById('tool')!=null){
      content = (<AppTool></AppTool>)
    } else if(document.getElementById('md_about')!=null){
      content = (<div style={{margin :'10px'}}><AppAbout></AppAbout></div>)
    } else if(document.getElementById('md_list')!=null){
      var table_content = document.getElementById('md_list').innerHTML.trim();
      var datas = JSON.parse(table_content);
      content = (<div style={{margin :'10px'}}><AppTable datas={datas}></AppTable></div>)
    } else if(document.getElementById('md')!=null){
      content = (<div style={{margin :'10px'}}><AppArticle></AppArticle></div>)
    } 

    return (
      <div>
        <AppTop></AppTop>
        {content}
        <AppBottom></AppBottom>
      </div>
    );
  }
}

export default App;