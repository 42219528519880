import React from 'react';
import './AppTool.css';

import AppToolRegex from './AppToolRegex.js';
import AppToolJson from './AppToolJson.js';
import AppToolSQL from './AppToolSQL.js';


class AppTool extends React.Component {

  render() {
    var content;
    var tool_name="";
    if(document.getElementById('tool_json')!=null){
        content = (<AppToolJson></AppToolJson>)
        tool_name = "Json工具";
    } else if(document.getElementById('tool_regex')!=null){
        content = (<AppToolRegex></AppToolRegex>)
        tool_name = "正则工具";
    } else if(document.getElementById('tool_sql')!=null){
        content = (<AppToolSQL></AppToolSQL>)
        tool_name = "SQL工具";
    }  else{
        content =<div id="tool_index"> 
            <table >
              <thead>
                <tr>
                  <td>工具</td>
                  <td>说明</td>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td><a href='/tool/regex'>正则工具</a></td>
                    <td>正则调试</td>
                  </tr>
                  <tr>
                    <td><a href='/tool/json'>JSON格式化</a></td>
                    <td>纯前端格式化，数据安全有保障：）</td>
                  </tr>
                  <tr>
                    <td><a href='/tool/sql'>SQL格式化</a></td>
                    <td>纯前端格式化，数据安全有保障：）</td>
                  </tr>
              </tbody>
            </table>
        </div>
    }
    return (
        <div>
            <div >
              {content}
            </div>
        </div>);
  }
}

export default AppTool;