import React from 'react';
import './AppArticle.css';
import marked from 'marked';

class AppArticle extends React.Component {

  constructor(props){
    super(props);
    this.markdown = document.getElementById("md").innerHTML.trim();
    var article_meta =  document.getElementById("md_meta").innerHTML.trim();
    this.title = document.title;
    this.meta = JSON.parse(article_meta);
  }

  getMarkdownText() {
    var rawMarkup = marked(this.markdown);
    return { __html: rawMarkup };
  }

  render() {
    return (
      <div id='article_view'>
        <div id='article_meta'>
          <h2 id='article_titile'>{this.title}</h2>
          <p id='article_meta_info'>
            <img height='14' weight='14' alt='' src='http://www.opstool.com/static/img/date.png'/>
            <span >{" "}</span>
            <span style={{'fontSize':'0.9em'}}>{" "} {this.meta.update_time}{" "}</span>
            <span style={{'marginRight': '10px'}}>{" "}</span>
            <img height='14' weight='14' alt='' src='http://www.opstool.com/static/img/tag.png'/>
            <span >{" "}</span>
            <span style={{"fontSize":"0.9em"}}>{this.meta.tags}{" "}</span>
          </p>
        </div>
        <div id='article_content' dangerouslySetInnerHTML={this.getMarkdownText()} />
      </div>
    );
  }
}

export default AppArticle;