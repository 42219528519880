import React from 'react';
import './AppToolJson.css';

class AppToolJson extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      fmted:"",
    }
    this.reChange = this.reChange.bind(this);
  }

  async componentDidMount() {
    const tools_wasm = import('tools-wasm');
    await tools_wasm.then(tools_wasm=>{
      this.setState({tools_wasm: tools_wasm});
    })
  }

  reChange(e){
    this.setState(
        (prevState) => ({
          tools_wasm : prevState.tools_wasm,
          fmted : prevState.tools_wasm.format_json(e.target.value),
        })
    )
  }

  render() {
    console.log(this.state.fmted);
    return (
      <div id="tool_json">
        <div id="content_box">
          <textarea type="text" rows="8" placeholder="请输入待格式的json字符串。纯前端格式化，数据安全有保障：）" 
              cols="130" value={this.state.inputNum} onChange={this.reChange}></textarea>
          <pre>{this.state.fmted}</pre>
        </div>
      </div>
    );
  }
}

export default AppToolJson;