import React from 'react';
import copy from "copy-to-clipboard";

import './AppToolSQL.css';



class AppToolSQL extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      fmted:"",
      copy_msg: "",
    }
    this.reChange = this.reChange.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
  }

  async componentDidMount() {
    const tools_wasm = import('tools-wasm');
    await tools_wasm.then(tools_wasm=>{
      this.setState({tools_wasm: tools_wasm});
    })
  }

  reChange(e){
    this.setState(
        (prevState) => ({
          tools_wasm : prevState.tools_wasm,
          fmted : prevState.tools_wasm.format_sql(e.target.value),
          copy_msg: ""
        })
    )
  }

  handleCopy(e){
    if (copy(this.state.fmted)) {
      this.setState(
        (prevState) => ({
          tools_wasm : prevState.tools_wasm,
          fmted : prevState.fmted,
          copy_msg : "复制成功",
        })
      )
    } else {
      this.setState(
        (prevState) => ({
          tools_wasm : prevState.tools_wasm,
          fmted : prevState.fmted,
          copy_msg : "复制失败"
        })
      )
    }
  };

  render() {
    console.log(this.state.fmted);
    var copy_info  = (<div id='copybox'>
            <button onClick={this.handleCopy}>复制</button>
            <span>{this.state.copy_msg}</span>
          </div>)
    if(typeof(this.state.fmted) == "undefined" || this.state.fmted.length==0){
      var copy_info = (<div></div>)
    }
    var ret =  (
      <div id="tool_sql">
        <div id="content_box">
          <textarea type="text" rows="8" placeholder="请输入待格式的SQL。纯前端格式化，数据安全有保障：）" 
              cols="130" onChange={this.reChange}></textarea>
          {copy_info}
          <pre>{this.state.fmted}</pre>
        </div>
      </div>
    );

    return ret
  }
}

export default AppToolSQL;