import React from 'react';
import './AppToolRegex.css';

class AppToolRegex extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      re : '',
      qstr : ''
    }

    this.reChange = this.reChange.bind(this);
    this.qstrChange = this.qstrChange.bind(this);
    this.regTest = this.regTest.bind(this);
  }

  reChange(e){
    this.setState(
        (prevState) => ({
          re:  e.target.value,
          qstr:  prevState.qstr
        })
    )
  }

  qstrChange(e){
    this.setState(
        (prevState) => ({
          re:  prevState.re,
          qstr:  e.target.value
        })
    )
  }

  regTest(){
    this.setState(
      (prevState) => ({
        re:  '(H[a-zA-Z]*)[\\s](.*)',
        qstr:  'Hello Opstool!'
      })
  )

  }

  render() {
    var result;
    if(this.state.re!=='' || this.state.qstr!==''){
      try {
        var reg = RegExp(this.state.re);
        var ret = this.state.qstr.match(reg);

        if(ret.length>1){
          var group_vals = []
          for(var i=1;i<ret.length;i++){
              group_vals.push(<tr key={i}><td>{i + '. '}</td><td><pre style={{'marginLeft':'10px'}}>{' '+ret[i]}</pre></td></tr>)
          }
          result = (<div id='result'>
                      匹配结果:<br/><div id='match_line'><pre>{ret[0]}</pre></div>
                      匹配组:<br/><div id='match_groups'><table><tbody>{group_vals}</tbody></table></div>
              </div>)
        } else {
          result = (<div id='result'>
              匹配结果:<br/><div id='match_line'><pre>{ret[0]}</pre></div>
            </div>)
        }

        if(this.state.re===''){
          result = (<div id='result'>
            匹配结果:
              <div className="notice">请填写正则</div>
            </div>)
        }

      } catch (error) {
        result = (<div id='result'>
          匹配结果:
        <div className='notice error'>无匹配</div>
      </div>)
      }
    } else {
      result = <div id='result'>
                      <br/>
                      <div className="notice">
                  在线正则编辑器，所见即所得<br/>
                  在上方输入框输入正则，左边的框输入测试字符串<br/>
                  立即开始-><br/>
                  或者可以
                  {/* <strong> <a href="#" onClick={this.regTest} id='tryone'>点击查看例子</a></strong> */}
                  <strong><span style={{'cursor': 'pointer'}} onClick={this.regTest}>点击查看例子</span></strong>
                      </div>
                </div>
    }

    return (
      <div id="tool_regex">
        <div id='main'>
        <div id='c1'>正则表达式:<br/>
          <input type='text' name='re' spellCheck="false" autoComplete='off' className='dyload inputbox' id='re' value={this.state.re} onChange={this.reChange} size='100'/>
        </div>
        <div id='c2'>
        <div id='test_str'>
            <div id='test_str_wapper'>测试字符串:<br/>
              <textarea cols="50" rows="7" spellCheck="false" autoComplete='off' className='dyload inputbox' name="qstr" value={this.state.qstr} onChange={this.qstrChange} id='qstr'></textarea>
            </div>
        </div>
        {result}
          </div>
            <div id='c3'></div>
        </div>
        <h3>正则表达式快速参考</h3>
        <div id="quickref">
          <div>
            <div style={{'float':'left','marginLeft':'160px'}}>
              <table>
                <tbody>
                  <tr>
                    <td><code>{'.'}</code></td>
                    <td>匹配任意单个字符</td>
                  </tr>
                  <tr>
                    <td><code>{'\\s'}</code></td>
                    <td>匹配空白字符，如空格，TAB</td>
                  </tr>
                  <tr>
                    <td><code>{'\\S'}</code></td>
                    <td>匹配非空白字符</td>
                  </tr>
                  <tr>
                    <td><code>{'\\d'}</code></td>
                    <td>匹配一个数字</td>
                  </tr>
                  <tr>
                    <td><code>{'\\D'}</code></td>
                    <td>匹配非数字</td>
                  </tr>
                  <tr>
                    <td><code>{'\\w'}</code></td>
                    <td>匹配一个字母</td>
                  </tr>
                  <tr>
                    <td><code>{'\\W'}</code></td>
                    <td>匹配非字幕</td>
                  </tr>
                  <tr>
                    <td><code>{'\\b'}</code></td>
                    <td>匹配字符边界</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{'float':'left','marginLeft':'160px','position':'relative'}}>
              <table>
                <tbody>
                  <tr>
                    <td><code>.</code></td>
                    <td>匹配任意单个字符</td>
                  </tr>
                  <tr>
                    <td><code>\s</code></td>
                    <td>匹配空白字符，如空格，TAB</td>
                  </tr>
                  <tr>
                    <td><code>\S</code></td>
                    <td>匹配非空白字符</td>
                  </tr>
                  <tr>
                    <td><code>\d</code></td>
                    <td>匹配一个数字</td>
                  </tr>
                  <tr>
                    <td><code>\D</code></td>
                    <td>匹配非数字</td>
                  </tr>
                  <tr>
                    <td><code>\w</code></td>
                    <td>匹配一个字母</td>
                  </tr>
                  <tr>
                    <td><code>\W</code></td>
                    <td>匹配非字幕</td>
                  </tr>
                  <tr>
                    <td><code>\b</code></td>
                    <td>匹配字符边界</td>
                  </tr>
              </tbody>
            </table>
            </div>
            <div style={{'float':'left','marginLeft':'160px','position':'relative'}}>
              <table>
                  <tbody>
                    <tr>
                      <td><code>{'(...)'}</code></td>
                      <td>引用所有括号中的内容</td>
                    </tr>
                    <tr>
                      <td><code>{'(a|b)'}</code></td>
                      <td>a或者b</td>
                    </tr>
                    <tr>
                      <td><code>{'a?'}</code></td>
                      <td>零个或多个a</td>
                    </tr>
                    <tr>
                      <td><code>{'a*'}</code></td>
                      <td>零个或多个a</td>
                    </tr>
                    <tr>
                      <td><code>{'a+'}</code></td>
                      <td>1个或多个a</td>
                    </tr>
                    <tr>
                      <td><code>{'a{3}'}</code></td>
                      <td>3次重复的a</td>
                    </tr>
                    <tr>
                      <td><code>{'a{3,}'}</code></td>
                      <td>3次或3次以上重复的a</td>
                    </tr>
                    <tr>
                      <td><code>{'a{3,6}'}</code></td>
                      <td>3到6次重复的a</td>
                    </tr>
                  </tbody>
            </table>
            </div>
            <div style={{'clear':'both'}}></div>
          </div>

        </div>
      </div>
    );
  }
}

export default AppToolRegex;