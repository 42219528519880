import React from 'react';
import './AppTable.css';


class AppTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            pageNum: 1,
            openSearch: false,
            searchText: '',
        }

        this.all_datas = this.props.datas;
        this.per_page_count = 16;

        this.headerSearchClick = this.headerSearchClick.bind(this);
        this.searchClick = this.searchClick.bind(this);
        this.searchEnter = this.searchEnter.bind(this);
    }

    pageClick(num){
        this.setState(
            (prevState) => ({
                pageNum: num,
                openSearch:  prevState.openSearch,
                searchText:  prevState.searchText
            })
        )
    }

    headerSearchClick(e){
        this.setState(
            (prevState) => ({
                pageNum: prevState.pageNum,
                openSearch: !prevState.openSearch,
                searchText: ''
            })
        )
    }

    doSearch(){
        console.log("do search")
        var searchText = document.getElementById('search_input').value;
        this.setState(
            (prevState) => ({
                pageNum: 1,
                openSearch: prevState.openSearch,
                searchText: searchText
            })
        )
    }

    searchClick(e){
        this.doSearch();
    }

    searchEnter(e){
        if (e.key === 'Enter') {
            this.doSearch();
        }
    }

    render(){
        this.datas = [];
        if(this.state.searchText.trim()!=='' && this.state.openSearch){

            var search_words = this.state.searchText.toLowerCase().split(' ');

            for(var i=0; i<this.all_datas.length; i++){
                var targetText = this.all_datas[i].title.toLowerCase() + ' ' + this.all_datas[i].tags.toLowerCase();

                var is_find = true;
                for(var j=0; j<search_words.length; j++){
                    if(targetText.indexOf(search_words[j])===-1){
                        is_find = false;
                        break;
                    }
                }

                if(is_find){
                    this.datas.push(this.all_datas[i]);
                }
            }
        } else {
            this.datas = this.all_datas;
        }

        var searchRow = (<tr key='search_row'><td colSpan='4'>
            <input type='text' id='search_input' autoComplete="off" onKeyDown={this.searchEnter}></input>
            {' '}
            <input type="submit" value="搜索" id="search_button"  onClick={this.searchClick}></input>
        </td></tr>)

        var rows = [];

        if(this.state.openSearch){
            rows.push(searchRow)
        }

        for(i=this.per_page_count * (this.state.pageNum-1);i<this.per_page_count * this.state.pageNum;i++){
            if(i>=0 && i<this.datas.length){
                rows.push((
                    <tr className='hovers' key={i}>
                        <td> <img alt='' style={{'marginTop':'4px'}} height='18' weight='18' src={'http://www.opstool.com/static/img/'+this.datas[i].icon}/> 
                <a style={{'fontSize':'1.1em'}} href={'http://www.opstool.com/article/'+this.datas[i].pid}>{' '}{this.datas[i].title}</a>
                        </td>
                        <td>{this.datas[i].tags}</td>
                        <td style={{'textAlign':'center'}}>{this.datas[i].update_time}</td>
                    </tr>
                ))
            }
        }

        var total_page_count = Math.ceil(this.datas.length/this.per_page_count);
        var cur_page_num = this.state.pageNum;
        var pre_hidden_show = false;
        var post_hidden_show = false;
        var pages = []; 
        if(cur_page_num===1){
            pages.push((<span key='prev_empty' className="pager pageempty"> 上一页</span>))
        } else{ 
            pages.push((<a key='prev_link' className="pager" style={{'cursor': 'pointer'}} onClick={this.pageClick.bind(this, cur_page_num-1)}>上一页</a>));        
        }

        for(i=1;i<=total_page_count;i++){
            if(i===cur_page_num){
                pages.push((<a key={i} className="pager" id="pageselect">{i}</a>));
            } else if((cur_page_num-i>=3) ) {
                if(!pre_hidden_show){
                    pages.push((<span key={i} className="pager pageempty" >...</span>));
                    pre_hidden_show = true;
                }
            } else if((i-cur_page_num>=3) ) {
                if(!post_hidden_show){
                    pages.push((<span key={i} className="pager pageempty" >...</span>));
                    post_hidden_show = true;
                }
            } else {
                pages.push((<a key={i} className="pager" style={{'cursor': 'pointer'}} onClick={this.pageClick.bind(this, i)} >{i}</a>));
            }
        }

        if(total_page_count === 0 ){
            pages.push((<span key='no_content' className="pager pageempty" >无数据</span>));
        }
        if(cur_page_num===total_page_count|| total_page_count===0){
            pages.push((<span key='next_empty' className="pager pageempty" >下一页</span>));
        } else{
            pages.push((<a key='next_link' className="pager" style={{'cursor': 'pointer'}} onClick={this.pageClick.bind(this, cur_page_num+1)}>下一页</a>));
        }

        // 
        return (
          <table id='mytable'>
            <thead>
                <tr><td style={{'width':'50%'}}>
                    标题<span style={{'float':'right','color': '#888','cursor': 'pointer'}} 
                    onClick={this.headerSearchClick}
                    >Ⓢ</span>
                </td><td style={{'width':'30%'}}>标签</td><td style={{'width':'20%'}}>更新时间</td></tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
            <tfoot>
                <tr style={{'width' : '100%'}}>
                    <td colSpan='4' id='pagelist'>
                    <span style={{'float' : 'right'}}>
                    {pages}
                    </span>
                    </td>
                </tr>
            </tfoot>
          </table>
        );
    }
}

export default AppTable;