import React from 'react';
import './AppTop.css';

class AppTop extends React.Component {

  render() {
    return (
      <div id='headbar' > 
        <div id='logo'>
          <h1 style={{display:'inline'}}>
            <a href='/'> Opstool</a>
          </h1>
        </div>
        <div id='toprightlinks'>
          <span className='link1'><a href='/'>博客</a></span>
          <span className='link2'><a href='/tool/'>工具</a></span>
          <span className='link3'><a href='http://www.opstool.com/about'>关于</a></span>
          <span></span>
        </div>
      </div>
    );
  }
}

export default AppTop;