import React from 'react';

class AppAbout extends React.Component {

  render() {
    return (
      <div>
        <p>
本站所有文章表达的观点仅代表个人，和任何组织无关。
欢迎wx交流 : <br/>
<img height='128px' width='128px' src='http://www.opstool.com/static/img/wx.jpg' alt='wx'></img>
        </p>
      </div>
    );
  }
}

export default AppAbout;