import React from 'react';
import './AppBottom.css';

class AppBottom extends React.Component {

  render() {
    let year = new Date().getFullYear();
    return (
      <div className="beian_div"> 
      <table className="beian">
        <tbody>
          <tr>
            <td>
              2013-{year}
              <a href="https://beian.miit.gov.cn/" rel="noreferrer" target="_blank"> 浙ICP备12030573号-1 </a>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    );
  }
}

export default AppBottom;